import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'services/http';
import { API_ENDPOINTS_PAISES } from '../../app/constants/api.constant';
import { LoginService } from '../login/login.service';
import { Paises } from '../../entities/paises.entity';

@Injectable({
  providedIn: 'root'
})
export class GeneralListsService extends HttpService {

  constructor(protected http: HttpClient,
              protected auth: LoginService,) {
    super(http, auth)
  }


  public getPaises() {
    return this.get<Paises>(API_ENDPOINTS_PAISES).map(data => data.paises);
  }


}
